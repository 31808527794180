import {GET_DATA_FROM_EXCEL} from "@/store/exam";

const API = {
    //users
    ADD_USERS: '/users',
    DETAIL_USER: '/users',
    UPDATE_USER: '/users',
    DELETE_USER: '/users',

    //admins
    ADD_ADMINS: '/admins',
    DETAIL_ADMIN: '/admins',
    UPDATE_ADMIN: '/admins',
    DELETE_ADMIN: '/admins',

    //role
    API_ROLE: '/roles',

    //permission
    API_PERMISSION: '/permissions',
    //exam
    DETAIL_EXAM_BY_SLUG_USER: "examsBySlugUser",
    
    //personal_access_key
    API_PERSONAL_ACCESS_KEY: '/personalAccessKeys',

    //company
    API_COMPANY: '/companies',

    GET_LIST_EXAM : 'exams',
    GET_LIST_EXAM_SEARCH : 'exams/search',
    GET_LIST_EXAM_COMMERCE_SEARCH : 'exam-commerce/search',
    APPROVAL_EXAM_COMMERCE : 'approval/exam-commerce',
    GET_EXAM_DETAIL : 'exams',
    ADD_EXAM : 'exams',
    UPDATE_EXAM : 'exams',
    DELETE_EXAM : 'exams',
    TAKE_EXAM: 'take-exam',
    TAKE_EXAM_SEARCH : 'takeExams/search',
    //exam intro
    GET_LIST_EXAM_INTRO : 'examIntros',
    API_EXAM_INTRO : 'examIntros',
    //temporary exam
    GET_LIST_TEMPORARY_EXAM_SEARCH : 'temporaryExams/search',
    GET_LIST_TEMPORARY_EXAM: 'temporaryExams',
    GET_DETAIL_TEMPORARY_EXAM: 'temporaryExams',
    UPDATE_TEMPORARY_EXAM: 'temporaryExams',
    ADD_TEMPORARY_EXAM: 'temporaryExams',
    DELETE_TEMPORARY_EXAM: 'temporaryExams',
    //import excel
    GET_DATA_FROM_EXCEL: '/getDataFromExcel',

    //category
    API_CATEGORY: 'categories',
    BLOCK_OR_UN_BLOCK_USER: "block-or-unblock-user",
    // student
    STUDENT_SEARCH : 'student-search'
}
export default  API